import React, { forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
// tools
import styled, { keyframes } from "styled-components";
import theme from 'theme';
import Icon from "utils/icon";
// - Socket
// import Io, { onSessionChange } from "../../actions/socket";
//i18n
import { FormattedMessage } from "react-intl";
import messages from "./messages";
// actions
import {
  getMySessions as getMySessionsAction,
  selectMySession as selectMySessionAction
} from 'actions/mySession';
import { useEffect } from "react";
// containers
import MySession from './MySession';
// components
import Button from "components/Button/Button";
import ContentPaginate from "containers/Session/ListSession/components/ContentPaginate";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import DatePickerDS from "../Session/ListSession/components/DatePickerDS";
import DatePickerDE from "../Session/ListSession/components/DatePickerDE";

const MySessionsCont = styled.div`
  height: calc(100% - 72px);
  overflow-y: auto;
`;

const MySessionCont = styled.div`
  padding: 16px;
  border-radius: 4px;
  background: ${theme.aliceblue};
  color: ${theme.darkgray};
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  border: 1px solid ${theme.ui.grey};
`;

const SessionInProgress = styled.div`
  margin: 16px 32px;

  @media(max-width: 768px) {
    margin: 16px;
  }
`;

const ListSessions = styled.div`
  margin-top: 16px;
`;

const FilterSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 32px;

  @media(max-width: 1050px) {
    flex-flow: column;
    margin: 16px;
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-flow: row;
  gap: 8px;

  @media(max-width: 1050px) {
    width: 100%;
  }
`;

const ButtonIncoming = styled.div`
  color: ${theme.ui.blue};
  cursor: pointer;
  border-radius: 5px;
  padding: 8px;
  border: 1px solid ${theme.ui.blue};
  height: fit-content;
  width: fit-content;

  ${props => props.selected && `
    background-color: ${theme.ui.blue};
    color: ${theme.white};
  `}

  @media(max-width: 1050px) {
    width: fit-content;
    text-align: center;
  }
`;

const ButtonFinish = styled.div`
  color: ${theme.ui.blue};
  border-radius: 5px;
  cursor: pointer;
  padding: 8px;
  border: 1px solid ${theme.ui.blue};
  height: fit-content;
  width: fit-content;

  ${props => props.selected && `
    background-color: ${theme.ui.blue};
    color: ${theme.white};
  `}

  @media(max-width: 1050px) {
    width: fit-content;
    text-align: center;
  }
`;

const ButtonRefresh = styled(ButtonFinish)`
  border-color: ${theme.gray};

  &:hover {
    border-color: ${theme.ui.blue};
  }
`;

const Filters = styled.div`
  display: flex;
  flex-flow: row;
  gap: 16px;
  justify-content: flex-end;

  @media(max-width: 1050px) {
    display: flex;
    flex-flow: column;
    width: 100%;
    margin-top: 16px;
  }
`;

const Input = styled.div`
  border-radius: 5px;
  border: 1px solid ${theme.ui.grey};
  padding: 8px;
  display: flex;
  height: fit-content;
  justify-content: space-between;
  align-items: center;

  @media(max-width: 1050px) {
  }
`;

const LeftSide = styled.div`
  display: flex;
  gap: 8px;
`;

const InputFilter = styled.input`
  border: none;
  outline: none;

  @media(max-width: 1050px) {

  }
`;

const Cross = styled.div`
  cursor: pointer;
`;

const StartDate = styled.div`
  display: flex;
  flex-flow: column;
  gap: 8px;

  @media(max-width: 1050px) {
    width: 100%;
  }
`;

const EndDate = styled.div`
  display: flex;
  flex-flow: column;
  gap: 8px;

  @media(max-width: 1050px) {
    width: 100%;
  }
`;

const InputDate = styled.div`
  border: 1px solid ${theme.ui.grey};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  padding: 8px;
`;

const SessionIncoming = styled.div`
  display: flex;
  flex-flow: wrap;
  gap: 8px;
  margin: 16px 32px;

  @media(max-width: 1050px) {
    display: flex;
    flex-flow: column;
    margin: 16px;
  }
`;

const MySessionIncoming = styled.div`
  background-color: ${theme.darkwhite};
  border: 1px solid ${theme.ui.grey};
  padding: 16px;
  border-radius: 4px;
  display: flex;
  flex: 40%;
  justify-content: space-between;
  align-items: center;
`;

const SessionFinish = styled.div`
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  gap: 8px;
  margin: 16px 32px;

  @media(max-width: 1050px) {
    display: flex;
    flex-flow: column;
    margin: 16px;
  }
`;

const MySessionFinish = styled.div`
  background: ${theme.aliceblue};
  border: 1px solid ${theme.ui.grey};
  color: ${theme.darkgray};
  padding: 16px;
  border-radius: 4px;
  display: flex;
  flex: 40%;
  justify-content: space-between;
  align-items: center;
`;

const MySessionInfo = styled.div`
  display: flex;
  flex-direction: column;;
  justify-content: space-between;
  align-items: flex-start;
  gap: 8px;
  text-align: left;
  width: 60%;
`;

const SessionTitle = styled.div`
  color: ${theme.darkgray};
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 18px;

  @media(max-width: 1050px) {
  }
`;

const SessionDate = styled.div`
  background-color: ${theme.specific.blue.light};
  color: ${theme.blue};
  border-radius: 5px;
  border: 1px solid ${theme.blue};
  padding: 2px 6px;
  font-size: 16px;

  @media(max-width: 768px) {
    margin-right: 0px;
  }
`;

const SessionData = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  white-space: nowrap;
  color: ${theme.darkgray};
  align-items: center;
  gap: 8px;

  @media(max-width: 1050px) {
    flex-direction: column;
    align-items: start;
  }
`;

const SessionLocation = styled.div`

  @media(max-width: 768px) {
    margin-right: 0px;
  }
`;

const SessionLink = styled.a`
  color: ${theme.blue};

  @media(max-width: 768px) {
    margin-right: 0px;
  }
`;

const Description = styled.div`
  color: gray;
  font-size: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  @media(max-width: 1050px) {
  }
`;

const Shine = keyframes`
  to {
    background-position-x: -200%;
  }
`;

const MySessionsLoading = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: 8px;
`;

const MySessionLoading = styled(MySessionCont)`
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1s ${Shine} linear infinite;
`;

const CustomDateInput = styled.input`
  border: none;
  width: 90%;

  &:focus {
    display: none;
  }
`;

const ButtonStatus= styled(Button)`
  height: fit-content;
`

const CustomInput = forwardRef(({ value, onClick }, ref) => (
  <CustomDateInput value={value} onClick={onClick}></CustomDateInput>
));


const MySessions = () => {
  // bind state
  const { mySessions, selectedMySession, fetchingMySession } = useSelector((state) => state.mySession);
  const { locale } = useSelector((state) => state.languageReducer);
  // bind actions
  const dispatch = useDispatch();
  const getMySessions = (pageNow, pageIncoming, pagePast, type) => dispatch(getMySessionsAction(pageNow, pageIncoming, pagePast, type));
  const selectMySession = (session) => dispatch(selectMySessionAction(session));
  
  const [pageNow, setPageNow] = React.useState(1);
  const [pageIncoming, setPageIncoming] = React.useState(1);
  const [pagePast, setPagePast] = React.useState(1);
  const [sessionSelected, setSessionSelected] = React.useState(0); // 0 = à venir / 1 = terminée

  const avoidInitialIncoming = React.useRef(false);
  const avoidInitialPast = React.useRef(false);
  const avoidInitialNow = React.useRef(false);

  // const maxPageNow = Math.ceil(mySessions.mySessionsNow && mySessions.mySessionsNow[1] / 3);
  // const maxPageIncoming = Math.ceil(mySessions.mySessionsIncoming && mySessions.mySessionsIncoming[1] / 3);
  // const maxPagePast = Math.ceil(mySessions.mySessionsPast  && mySessions.mySessionsPast[1] / 3);
  
  useEffect(() => {
    if (avoidInitialIncoming.current) {
      getMySessions(pageNow, pageIncoming, pagePast, "incoming");
    } else {
      avoidInitialIncoming.current = true;
      getMySessions(pageNow, pageIncoming, pagePast, "all");
    }
  }, [pageIncoming]);

  useEffect(() => {
    if (avoidInitialNow.current) {
      getMySessions(pageNow, pageIncoming, pagePast, "now");
    } else {
      avoidInitialNow.current = true;
    }
  }, [pageNow]);

  useEffect(() => {
    if (avoidInitialPast.current) {
      getMySessions(pageNow, pageIncoming, pagePast, "past");
    } else {
      avoidInitialPast.current = true;
    }
  }, [pagePast]);

  const getSessionStatus = (session) => {
    let textKey = "";
    switch(session.status) {
      case 0:
        textKey = "waiting";
        break;
      case 1:
        textKey = "open";
        break;
      case 2:
        textKey = "closed";
        break;
      default:
        textKey = "default";
        break;
    }
    return (
      <ButtonStatus
        onClick={() => session.status >=0 && selectMySession(session)}
      >
        <FormattedMessage {...messages[textKey]} />
      </ButtonStatus>
    )
  }

  const getDate = (session) => {
    if(session?.date) {
      const date = displayDate(session.date)
      const time = displayTime(session.date);
      return <SessionDate>{date} - {time}</SessionDate>;
    }
    return null;
  }

  const displayDate = (date) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return locale == "fr" ? new Date(date).toLocaleDateString('fr-FR', options) : new Date(date).toLocaleDateString('en-EN', options);
  }

  const displayTime = (date) => {
    const options = { hour: '2-digit', minute: '2-digit' };
    return locale == "fr" ? new Date(date).toLocaleTimeString('fr-FR', options) : new Date(date).toLocaleTimeString('en-EN', options);
  }

  const getEndDate = (date) => {
    const dateEnd = displayDate(date);
    const timeEnd = displayTime(date);
    return <SessionDate>{<FormattedMessage {...messages.ended} /> } {dateEnd} - {timeEnd}</SessionDate>;
  }

  const getLocations = (session) => {
    const { location, location2 } = session;
    return (
      <>
        {
          location &&
            <SessionLocation>
               <FormattedMessage {...messages.at} />{" : "}{location}
            </SessionLocation>
        }
        {
          location2 &&
            <SessionLocation>
              <FormattedMessage {...messages.link} />{" : "}
              <SessionLink href={location2} target="_blank">
                {location2}
              </SessionLink>
            </SessionLocation>
        }
      </>

    )
  }

  const displayMySessionsInProgress = (session) => {
    return (
      <MySessionCont key={session.id}>
        <MySessionInfo>
          <SessionTitle>{session.title}</SessionTitle>
          { session?.description ?
          <Description>{session.description}</Description>
          : <></>
          }
          { session?.location && session?.location2 ?
            <SessionData>
              {getLocations(session)}
            </SessionData>
            :
            <></>
          }
        </MySessionInfo>
        {getSessionStatus(session)}
      </MySessionCont>
    )
  }

  const displayMySessionsIncoming = (session) => {
    return (
      <MySessionIncoming key={session.id}>
        <MySessionInfo>
          <SessionTitle>{session.title}</SessionTitle>
          { session?.description ?
            <Description>{session.description}</Description>
            :
            <></>
          }
          { session?.location && session?.location2 && session?.date ?
            <SessionData>
              {getDate(session)}
              {getLocations(session)}
            </SessionData>
            :
            <></>
          }
        </MySessionInfo>
        {getSessionStatus(session)}
      </MySessionIncoming>
    )
  }

  const displayMySessionsFinish = (session) => {
    return (
      <MySessionFinish key={session.id}>
        <MySessionInfo>
          <SessionTitle>{session.title}</SessionTitle>
          { session?.description ?
            <Description>{session.description}</Description>
            :
            <></>
          }
          { session?.endDate ?             
            <>
            {getEndDate(session.endDate)}
            </>
            :
            <></>
          }
          { session?.location && session?.location2 && session?.date ?
            <SessionData>
              {getDate(session)}
              {getLocations(session)}
            </SessionData>
            :
            <></>
          }
        </MySessionInfo>
        {getSessionStatus(session)}
      </MySessionFinish>
    )
  }

  const loading = () => {
    return (
      <MySessionsLoading>
        <MySessionLoading>
        </MySessionLoading>
        <MySessionLoading>
        </MySessionLoading>
        <MySessionLoading>
        </MySessionLoading>
      </MySessionsLoading>
    )
  }

  return (
    <MySessionsCont>
      {/* <Io /> */}
      {
        selectedMySession ?
          <MySession />
        :
          <>
            <SessionInProgress>
              <Buttons>
                <Button>
                  <FormattedMessage {...messages.list_title} />
                </Button>
                <ButtonRefresh onClick={() => getMySessions(pageNow, pageIncoming, pagePast, "all")}>
                  <Icon icon="Refresh" size="sm" />
                </ButtonRefresh>
              </Buttons>
              <ListSessions>
              {
                fetchingMySession == "now" || fetchingMySession == "all" ?
                  loading()
                :
                mySessions?.mySessionsNow?.length && mySessions.mySessionsNow[0]?.length ?
                <Carousel showThumbs={false} showStatus={false} swipeable={true} useKeyboardArrows={true} stopOnHover={true} autoPlay={true} infiniteLoop={true} interval={5000} transitionTime={500}>
                {
                mySessions?.mySessionsNow?.length && mySessions.mySessionsNow[0]?.map((session) => {
                  return (
                    displayMySessionsInProgress(session)
                    )
                  })
                }
                </Carousel>
                :
                <FormattedMessage {...messages.no_session_now} />
              }
              </ListSessions>
            </SessionInProgress>

            <FilterSection>
              <Buttons>
                <ButtonIncoming selected={sessionSelected == 0} onClick={() => setSessionSelected(0)}>
                  <FormattedMessage {...messages.incoming} />
                </ButtonIncoming>
                <ButtonFinish backgroundColor={"aliceblue"} textColor={"blue"} selected={sessionSelected == 1} onClick={() => setSessionSelected(1)}>
                  <FormattedMessage {...messages.finish} />
                </ButtonFinish>
              </Buttons>

              {/* Logique à faire sur les filtres, par date et recherche par nom/description */}
              {/* <Filters>
                <Input>
                  <LeftSide>
                    <Icon icon="Search" size="sm"/>
                    <InputFilter
                      type="text"
                      placeholder="Recherche d'une séance..."
                    />
                  </LeftSide>
                  <Cross>
                    <Icon icon="Cross" size="sm" />
                  </Cross>
                </Input>
                <StartDate>
                  <InputDate>
                    <DatePickerDS
                      filters
                      customInput={<CustomInput />}
                    />
                    <Cross>
                      <Icon icon="Cross" size="sm"/>
                    </Cross>
                  </InputDate>
                </StartDate>
                <EndDate>
                  <InputDate>
                    <DatePickerDE
                      filters
                      customInput={<CustomInput />}
                    />
                    <Cross>
                      <Icon icon="Cross" size="sm"/>
                    </Cross>
                  </InputDate>
                </EndDate>
              </Filters> */}
            </FilterSection>
            { sessionSelected == 0 ?
            <SessionIncoming>
              {
                fetchingMySession == "incoming" || fetchingMySession == "all" ?
                  loading()
                :
                mySessions?.mySessionsNow?.length && mySessions.mySessionsIncoming[0]?.length ?
                  mySessions.mySessionsIncoming && mySessions.mySessionsIncoming[0]?.map((session)=>{
                    return displayMySessionsIncoming(session);
                  })
                :
                <FormattedMessage {...messages.no_session_incoming} />
              }
            </SessionIncoming>
            :
            <SessionFinish>
              {
                fetchingMySession == "past" || fetchingMySession == "all" ?
                  loading()
                :
                mySessions?.mySessionsPast?.length && mySessions.mySessionsPast[0]?.length ?
                  mySessions.mySessionsPast && mySessions.mySessionsPast[0]?.map((session)=>{
                    return displayMySessionsFinish(session);
                  })
                :
                <FormattedMessage {...messages.no_session_ended} />
              }
            </SessionFinish>
            }
          </>
      }
    </MySessionsCont>
  )
}

export default MySessions;