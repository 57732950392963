import React from "react";
// tools
import styled from "styled-components";
// containers
import MySessions from "containers/MySessions";
import HomeVoteHeader from "./HomeVoteHeader";

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
`;

const HomeVote = () => {
  return (
    <Container>
      <HomeVoteHeader/>
      <MySessions/>
    </Container>
  )
}

export default HomeVote;