import { A2VOTE_API } from "../utils/config";
import { getAuthorization } from "utils/token";
import { handleResponse, handleCatch } from "utils/handleResponse";

export function getMySessions(pn = 1, pi = 1, pp = 1, type = "all") {
  return dispatch => {
    dispatch({ type: "GET_MY_SESSIONS_START" , payload: type});
    const requestedOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': getAuthorization()
      },
    };
    fetch(`${A2VOTE_API}/session/my/${pn}/${pi}/${pp}`, requestedOptions)
      .then(handleResponse)
      .then((data) => {
        const { status, mySessions } = data;
        if (status === 200) {
          dispatch({ type: "GET_MY_SESSIONS_END", payload: mySessions });
        } else {
          dispatch({
            type: "GET_MY_SESSIONS_ERROR",
            payload: { errorType: null, message: "an error occurs" },
          });
        }
      })
      .catch(handleCatch);
  }
}

export function selectMySession(session = null) {
  return dispatch => {
    dispatch({ type: "SELECT_MY_SESSION", payload: session})
  }
}

export function selectMySessionPage(page = 'home') {
  return dispatch => {
    dispatch({ type: "SELECT_MY_SESSION_PAGE", payload: page})
  }
}

export function selectMySessionVote(vote = null) {
  return dispatch => {
    dispatch({ type: "SELECT_MY_SESSION_VOTE", payload: vote})
  }
}

export function selectMyProxyVote(vote, proxy){
  return dispatch => {
    dispatch({ type: "SELECT_MY_PROXY_VOTE", payload: {vote, proxy}})
  }
}
export function getMySessionVote(sessionId) {
  return dispatch => {
    dispatch({ type: "GET_MY_SESSION_VOTES_START" })
    const requestedOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': getAuthorization()
      }
    };
    fetch(`${A2VOTE_API}/vote/my/s/${sessionId}`, requestedOptions)
      .then(handleResponse)
      .then((data) => {
        const { status, votes, session, proxies } = data;
        if (status === 200) {
          dispatch({ type: "GET_MY_SESSION_VOTES_END", payload: {votes, proxies, session} });
        } else {
          dispatch({
            type: "GET_SESSION_VOTES_ERROR",
            payload: { errorType: null, message: "an error occurs" },
          });
        }
      })
      .catch(handleCatch);
  }
}

export function sendMySessionVoteResult(voteId, voteResult, proxyId = null) {
  return dispatch => {
    dispatch({ type: "SEND_SESSION_VOTE_RESULT_START"});
    const data = {
      voteId,
      voteResult,
      proxyId
    }
    if(voteId && Number.isInteger(voteResult)) {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization': getAuthorization()
        },
        body: JSON.stringify(data)
      }
      fetch(`${A2VOTE_API}/result`, requestOptions)
        .then(handleResponse)
        .then((data) => {
          const { status, result } = data;
          if (status === 200) {

            dispatch({ type: "SEND_SESSION_VOTE_RESULT_END", payload: result });
          } else {
            dispatch({
              type: "SEND_SESSION_VOTE_RESULT_ERROR",
              payload: { errorType: null, message: "an error occurs" },
            });
          }
        })
        .catch(handleCatch);
    }
  }
}

export function clearMySessionVotes() {
  return dispatch => {
    dispatch({ type: 'CLEAR_MY_SESSION_VOTES' });
  }
} 