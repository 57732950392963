import { defineMessages } from "react-intl";
export default defineMessages({
  list_title: {
    id: "a2vote.mysessions.list",
    defaultMessage: "Current sessions",
  },
  pending: {
    id: "a2vote.mysessions.pending",
    defaultMessage: "Pending",
  },
  join: {
    id: "a2vote.mysessions.join",
    defaultMessage: "Join the session",
  },
  finish: {
    id: "a2vote.mysessions.finish",
    defaultMessage: "Session finished",
  },
  ended: {
    id: "a2vote.mysessions.ended",
    defaultMessage: "Session ended on",
  },
  unknown: {
    id: "a2vote.mysessions.unknown",
    defaultMessage: "Unknown state",
  },
  waiting: {
    id: "vote.status.waiting",
    defaultMessage: "Waiting"
  },
  open: {
    id: "vote.status.open",
    defaultMessage: "Open"
  },
  closed: {
    id: "vote.status.closed",
    defaultMessage: "Closed"
  },
  default: {
    id: "vote.status.default",
    defaultMessage: "Unknow"
  },
  0: {
    id: "vote.result.0",
    defaultMessage: "No"
  },
  1: {
    id: "vote.result.1",
    defaultMessage: "Yes"
  },
  2: {
    id: "vote.result.2",
    defaultMessage: "Abstention"
  },
  incoming: {
    id: "vote.mysession.incoming",
    defaultMessage: "Incoming session"
  },
  no_session_now: {
    id: "a2vote.mysessions.no.session.now",
    defaultMessage: "No session in progress"
  },
  no_session_incoming: {
    id: "a2vote.mysessions.no.session.incoming",
    defaultMessage: "No upcoming session"
  },
  no_session_ended: {
    id: "a2vote.mysessions.no.session.ended",
    defaultMessage: "No session ended"
  },
  at: {
    id: "a2vote.mysessions.location",
    defaultMessage: "at"
  },
  link: {
    id: "a2vote.mysessions.location2",
    defaultMessage: "link"
  }
});
