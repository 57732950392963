import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// tools
import styled from 'styled-components';
// actions
import { A2VOTE_API } from 'utils/config';
import { getPdfSrc } from 'utils/pdf';
import { Lazyload } from '../../../components/lazyload/lazyload';

const DocCont = styled.div`
  width: 100%;
  height: calc(100vh - 130px);
`;

const MyVoteDoc = () => {

  const { selectedVote } = useSelector((state) => state.mySession)
  //state
  const [numberPage, setNumberPage] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      if(selectedVote?.filename){
        const data = await fetch(`${A2VOTE_API}/file/m/${selectedVote?.filename}`);
        const json = await data.json();
        setNumberPage(json)
      }
    }
    fetchData()
      .catch(console.error);
  }, [])
  const displayFile = () => {
    const filename = selectedVote?.filename;
    if(filename) {
      if(filename.toLowerCase().includes('.pdf')) {
        let pdfSrc = getFileSource();
        pdfSrc = getPdfSrc(pdfSrc);
        return <iframe src={pdfSrc} width="100%" height="100%"  frameBorder="0"/>
      }
      else {
         if(numberPage == 1){
          return <Lazyload src={getFileSource()} />
        }
        else {
             const pages = [];
             for(let j=1;j<=numberPage;j++) {
               pages.push(<Lazyload key={j} src={getPagesFileSource(j)} />);
             }

           return (<div>{ pages }</div>);
        }
      }
    }
    return null;
  }
  const getPagesFileSource = (index) => {
    let filenameWithoutExtension = selectedVote.filename?.replace('.jpg','');
    if(filenameWithoutExtension){
      return `${A2VOTE_API}/file/a/${filenameWithoutExtension}/${index}`;
    }
  };
  const getFileSource = () => {
    if(selectedVote?.filename){
      return `${A2VOTE_API}/file/o/${selectedVote.filename}`;
    }
  };
  return (
    <div>
      <DocCont>
        {displayFile()}
      </DocCont>
    </div>
  )
}

export default MyVoteDoc;