import React, { useCallback, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
// tools
import styled from 'styled-components';
// actions
import { 
  selectMySession as selectMySessionAction,
  selectMySessionPage as selectMySessionPageAction,
  getMySessionVote as getMySessionVoteAction,
  selectMySessionVote as selectMySessionVoteAction,
  selectMyProxyVote as selectMyProxyVoteAction
} from "actions/mySession";
// components
import Button from "components/Button/Button";
//i18n
import { FormattedMessage } from "react-intl";
import globalMessages from "containers/messages"
import messages from "./messages";
//Syle
import Icon from "utils/icon";
import theme from "theme";
import { navigate } from "gatsby";
import { displayName } from '../../../utils/displayUserName';


const Buttons = styled.div `
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Return = styled.div`
  color: ${theme.darkgray};
  cursor: pointer;
  padding-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const TopSection = styled.div`
  padding: 16px 32px;
  border-bottom: 1px solid ${theme.ui.grey};

  @media(max-width: 768px) {
    padding: 16px;
  }
`;

const MyVotes = styled.div`
  padding: 32px;

  @media(max-width: 768px) {
    padding: 16px;
  }
`;

const RefreshButton = styled.div`
  margin-bottom: 16px;
`;

const MyVotesCont = styled.div`
`;

const MyVoteCont = styled.div`
  padding: 16px;
  border: 1px solid cornflowerblue;
  border-radius: 4px;
  background: aliceblue;
  color: darkslategray;
  margin-bottom: 16px;
`;

const TopContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const InfoDiv = styled.div`
  width: 50%;

`;

const Title = styled.div`
  color: ${theme.darkgray};
  font-size: 18px;
  font-weight: bold;
`;

const Description = styled.div`
  color: ${theme.mediumgray};
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ButtonsVote = styled.div`
  display: flex;
  gap: 8px;
`;

const ProxySection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Proxies = styled.div`
  border-top: 1px dashed ${theme.ui.grey};
  padding-top: 16px;
  margin-top: 16px;
  color: ${theme.ui.blue};

  & span {
    font-size: 18px;
    font-weight: bold;
  }
  & p {
    margin: 8px 0;
  }
`;

const Proxy = styled.div`
  border-radius: 5px;
  width: 100%;
  color: ${theme.darkgray};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const InfoMandating = styled.div`
  width: 40%;
`;

const Procuration = styled.div`
  font-weight: bold;
`;

const Mandating = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ProxyStatus = styled.div`
  display: flex;
  gap: 8px;
`

const MySessionHome = () => {
  // state
  const { votes, selectedMySession, votesByProxies } = useSelector((state) => state.mySession);
  // actions
  const dispatch = useDispatch();
  const selectMySession = () => dispatch(selectMySessionAction());
  const selectMySessionPage = (page) => dispatch(selectMySessionPageAction(page));
  const getMySessionVotes = useCallback((sessionId) => dispatch(getMySessionVoteAction(sessionId)), [dispatch]);
  const selectMySessionVote = (vote) => dispatch(selectMySessionVoteAction(vote));
  const selectMyProxyVote = (vote, proxy) => dispatch(selectMyProxyVoteAction(vote, proxy));

  const getVoteStatus = (vote, proxy = false) => {
    let textKey = "";
    switch(vote.status) {
      case 0:
        textKey = "waiting";
        break;
      case 1:
        textKey = "open";
        break;
      case 2:
        textKey = "closed";
        break;
      default:
        textKey = "default";
        break;
    }
    return displayStatusButton(vote, textKey, null, false, proxy)
  }

  const getUserVoteStatus = (vote, proxy = null) => {
    // Vote normal => userResult.simpleResult (option appliquée)
    // également si vote.results.by == vote.results.createdBy
    // Vote procuration => proxy.mandating == vote.results.by
    let voteResult = null;
    if(proxy) {
      const mandatingId = proxy?.mandating?.userId;
      const mandatingResult = vote?.results?.find((result)=>result?.by?.userId === mandatingId);
      voteResult = mandatingResult?.simpleResult;
    }
    else {
      voteResult = vote?.userResult?.simpleResult
    }

    if(Number.isInteger(voteResult)) {
      let textKey = "";
      let backgroundColor = null;
      switch(voteResult) {
        case 0:
          textKey = "0";
          backgroundColor = "red";
          break;
        case 1:
          textKey = "1";
          backgroundColor = "green";
          break;
        case 2:
          textKey = "2";
          break;
        default:
          textKey = "default";
          break;
      }
      return displayStatusButton(vote, textKey, backgroundColor, false, proxy)
    }
    return null;
  }

const handleOnClick = (vote, proxy = false) => {
    if(proxy) {
      selectMyProxyVote(vote, proxy);
    }
    else{
      selectMySessionVote(vote);
    }
  }

  const displayStatusButton = (vote, textKey, backgroundColor = null, disable = false, proxy = false ) => {
    return (
      <Button
        onClick={() => handleOnClick(vote, proxy)}
        disable={disable}
        backgroundColor={backgroundColor}
      >
        <FormattedMessage {...messages[textKey]} />
      </Button>
    )
  }

  return (
    <React.Fragment>
      <TopSection>
        <Return onClick={() => selectMySession()}>
          <Icon icon="LeftArrow" size="sm"/>
          <FormattedMessage {...globalMessages.return} />
        </Return>
        <Buttons>
          <Button onClick={() => selectMySessionPage('document')}>
            <FormattedMessage {...messages.order} />
          </Button>
          <Button disable={selectedMySession?.status !== 2} onClick={() => selectMySessionPage('report')}>
            <FormattedMessage {...messages.report} />
          </Button>
          <Button onClick={()=>navigate('/p')}>
            <FormattedMessage {...messages.attendees} />
          </Button>
        </Buttons>
      </TopSection>

      <MyVotes>
        <RefreshButton>
          <Button onClick={()=>getMySessionVotes(selectedMySession?.id)}>
            <FormattedMessage {...messages.refresh} />
          </Button>
        </RefreshButton>
        <MyVotesCont>
          {
            votes && votes.map((vote) => {
              return (
                <MyVoteCont key={vote.id}>
                  <TopContent>
                    <InfoDiv>
                      <Title>{vote.title}</Title>
                      <Description>{vote.description}</Description>
                    </InfoDiv>
                    <ButtonsVote>
                      {getVoteStatus(vote)}
                      {getUserVoteStatus(vote)}
                    </ButtonsVote>
                  </TopContent>
                    {
                    votesByProxies && votesByProxies.map((proxy) => {
                      return(
                      <Proxies key={proxy?.id} >
                        <ProxySection key={proxy.id}>
                          <Procuration><FormattedMessage {...messages.proxy} /></Procuration>
                          <Proxy>
                            <InfoMandating>
                              <Mandating>
                                {displayName(proxy?.mandating)}
                              </Mandating>
                            </InfoMandating>
                            <ProxyStatus>
                              {getVoteStatus(vote, proxy)}
                              {getUserVoteStatus(vote, proxy)}
                            </ProxyStatus>
                          </Proxy>
                        </ProxySection>
                      </Proxies>
                      )
                    })
                  }
                </MyVoteCont>
              )
            })
          }
        </MyVotesCont>
      </MyVotes>

    </React.Fragment>
  )
}

export default MySessionHome;