import React from "react";
// i18n
import { FormattedMessage } from "react-intl";
import messages from "./messages";
// styled
import {
  Buttons,
  PopUp,
  Card,
  Title,
  Description,
  VoteButton,
  VoteButtonGreen,
  VoteButtonRed,
  VoteButtonBlue
} from "./styled";

const ConfirmVote = ({selectedResult, confirmResult, resetResult}) => {

  const getButton = () => {
    switch(selectedResult) {
      case 0:
        return (
          <VoteButtonRed onClick={()=>confirmResult(0)}>
            <FormattedMessage {...messages.no} />
          </VoteButtonRed>
        )
      case 1:
        return (
          <VoteButtonGreen onClick={()=>confirmResult(1)}>
            <FormattedMessage {...messages.yes} />
          </VoteButtonGreen>
        )
      case 2:
        return (
          <VoteButtonBlue onClick={()=>confirmResult(2)}>
            <FormattedMessage {...messages.abstain} />
          </VoteButtonBlue>
        )
    }
  }
  return (
    <PopUp>
      <Card>
        <Title>
          <FormattedMessage {...messages.validate} />
        </Title>
        <Description>
          ** Confirmez-vous votre choix pour le vote ?
        </Description>
        <Buttons>
          {getButton()}
          <VoteButton onClick={resetResult}>
            <FormattedMessage {...messages.cancel} />
          </VoteButton>
        </Buttons>
      </Card>
    </PopUp>
  )
}

export default ConfirmVote;