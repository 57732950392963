import { defineMessages } from "react-intl";
export default defineMessages({
  yes: {
    id: "a2vote.myvote.yes",
    defaultMessage: "Yes",
  },
  no: {
    id: "a2vote.myvote.no",
    defaultMessage: "No",
  },
  abstain: {
    id: "a2vote.myvote.abstain",
    defaultMessage: "Abstain",
  },
  validate: {
    id: "a2vote.myvote.validate",
    defaultMessage: "Validate"
  },
  cancel: {
    id: "a2vote.cancel",
    defaultMessage: "Cancel"
  },
  resume_one: {
    id: "vote.text.resume.one",
    defaultMessage: "Participants voted"
  },
  unanimously: {
    id: "vote.text.resume.unanimously",
    defaultMessage: "unanimously"
  },
  mostly: {
    id: "vote.text.resume.mostly",
    defaultMessage: "mostly"
  },
  resume_two: {
    id: "vote.text.resume.two",
    defaultMessage: ", with"
  },
  abstention: {
    id: "vote.text.resume.abstention",
    defaultMessage: "abstention"
  },
  abstentions: {
    id: "vote.text.resume.abstentions",
    defaultMessage: "abstentions"
  },
  equality: {
    id: "vote.text.resume.equality",
    defaultMessage: "Participants voted equaly for and against"
  },
  noVote: {
    id: "vote.text.resume.noVote",
    defaultMessage: "No votes"
  }
});
