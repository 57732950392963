// React
import React from "react";
import HomeVote from "containers/HomeVote";
import UserLogged from "../containers/userLogged";

const H = () => {
  return (
    <UserLogged>
      <HomeVote />
    </UserLogged>
  );
};

export default H;
